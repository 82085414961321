<template>
<div>
    <el-form ref="form" :model="form" :rules="formRules" class="content bascform" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
      <div v-if="fieldShow.BidScore!==false" class="col col8">
        <el-form-item label="采购员备注：" prop="BidScore">
            <el-input v-model="form.bz" autosize type="textarea"></el-input>
        </el-form-item>
        <div class="tableList">
            <div class="table">
                <el-table border row-key="supplierName" :data="tableData" style="width: 100%">>
                    <el-table-column :show-overflow-tooltip="true" prop="supplierName" label="供应商名称"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" :key="index" v-for="(item,index) in scoreList" :prop="item.scoringId">
                        <template #header>
                            <el-tooltip class="item" effect="dark" :content="item.bidScoringMes" placement="top">
                                <div class="nowrap">{{item.bidScoringMes}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" prop="synthesisScore" label="综合得分"></el-table-column>
                </el-table>
            </div>
        </div>
      </div>
    </el-form>
    <div class="secondHeader">其他单位得分
      <span class="showOff">
        <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
          <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
        </el-button>
      </span>
    </div>
    <el-form v-if="!isclose" ref="form" :model="form" :rules="formRules" class="content bascform" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
      <div v-if="fieldShow.otherBidScore!==false" class="col col8">
          <div class="tableList">
            <div class="table">
                <el-table border row-key="supplierName" :data="tableDataGd" style="width: 100%">>
                    <el-table-column :show-overflow-tooltip="true" prop="supplierName" label="供应商名称"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" :key="index" v-for="(item,index) in scoreList" :prop="item.scoringId">
                        <template #header>
                            <el-tooltip class="item" effect="dark" :content="item.bidScoringMes" placement="top">
                                <div class="nowrap">{{item.bidScoringMes}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" prop="synthesisScore" label="综合得分"></el-table-column>
                </el-table>
            </div>
          </div>
      </div>
    </el-form>
</div>
</template>

<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'BidResult',
  props: ['formData', 'dataFormat'],
  data: function () {
    return {
      form: this.formData,
      formRules: this.dataFormat ? this.dataFormat.rules : {},
      formDisabled: true,
      fieldShow: {},
      tableData: [],
      tableDataGd: [],
      scoreList: [],
      scoreListGd: [],
      isclose: true
    }
  },
  mounted () {
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
      if (this.dataFormat.fieldShow) {
        this.fieldShow = this.dataFormat.fieldShow
      }
    }
    if (this.formData.id) {
      request('/api/bid/approvalScore?bidId=' + this.formData.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreList = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreList[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreList[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableData = tempTableList
        }
      })
      request('/api/bid/approvalNoWinningScore?bidId=' + this.formData.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreListGd = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreListGd[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreListGd[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreListGd) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreListGd) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableDataGd = tempTableList
        }
      })
    }
  },
  watch: {
    formData: function (val) {
      request('/api/bid/approvalScore?bidId=' + val.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreList = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreList[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreList[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreList) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableData = tempTableList
        }
      })
      request('/api/bid/approvalNoWinningScore?bidId=' + this.formData.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          this.scoreListGd = response.data
          var tempTableList = []
          var tempTeamList = new Set()
          // eslint-disable-next-line no-unused-vars
          for (var s = 0; s < this.scoreListGd[0].list.length; s++) {
            tempTableList.push({ supplierName: this.scoreListGd[0].list[s].supplierName, children: [] })
          }
          for (var d of this.scoreListGd) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  tempTableList[g][d.scoringId] = sd.score
                  if (tempTableList[g].synthesisScore === undefined) {
                    tempTableList[g].synthesisScore = parseFloat(sd.score)
                  } else {
                    tempTableList[g].synthesisScore = parseFloat(tempTableList[g].synthesisScore) + parseFloat(sd.score)
                  }
                  if (isNaN(parseFloat(tempTableList[g].synthesisScore))) {
                    tempTableList[g].synthesisScore = 0
                  }
                  tempTableList[g].synthesisScore = tempTableList[g].synthesisScore.toFixed(2)
                  for (var v of sd.deptUsernameList) {
                    tempTeamList.add(v.deptUsername)
                  }
                }
              }
            }
          }
          for (var j of tempTableList) {
            for (var b of tempTeamList) {
              j.children.push({ supplierName: b })
            }
          }
          /* eslint-disable no-redeclare */
          for (var d of this.scoreListGd) {
            for (var sd of d.list) {
              for (var g = 0; g < tempTableList.length; g++) {
                if (sd.supplierName === tempTableList[g].supplierName) {
                  for (var v of tempTableList[g].children) {
                    for (var sv of sd.deptUsernameList) {
                      if (sv.deptUsername === v.supplierName) {
                        v[d.scoringId] = sv.personSc
                        if (v.synthesisScore === undefined) {
                          v.synthesisScore = parseFloat(sv.personSc)
                        } else {
                          v.synthesisScore = parseFloat(v.synthesisScore) + parseFloat(sv.personSc)
                        }
                        if (isNaN(parseFloat(v.synthesisScore))) {
                          v.synthesisScore = 0
                        }
                        v.synthesisScore = v.synthesisScore.toFixed(2)
                      }
                    }
                  }
                }
              }
            }
          }
          this.tableDataGd = tempTableList
        }
      })
    }
  },
  methods: {
    open () {
      this.isclose = !this.isclose
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
 .showOff{
   float: right;
 }
 .secondHeader{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    color: #666;
    margin: 0 25px;
 }
 .tableList{
  padding: 0;
  width: 1200px;
 }
</style>
